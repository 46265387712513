import { atom } from 'recoil';

// microCMSのドメイン
export const ServiceDomainState = atom({
    key: 'ServiceDomainState',
    default: "foodlossrestaurant",
});

// microCMSのApiキー
export const ApiKeyState = atom({
    key: 'ApiKeyState',
    default: "GLCfseXkHHLCQHDO8DbFfWi80K3ACqLTCIHG",
});

// 取得したNEWS一覧データ
export const NewsDataState = atom({
    key: 'NewsDataState',
    default: [],
});

// 取得したNEWS個別記事データ
export const NewsSingleDataState = atom({
    key: 'NewsSingleDataState',
    default: [],
});

// 取得したNEWS個別記事データ
export const isNotFoundState = atom({
    key: 'isNotFoundState',
    default: false,
});

// ローディングのフラグ
export const isLoadingState = atom({
    key: 'isLoadingState',
    default: false,
});
