import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const getJstDateTime = (date, type) => {
    return dayjs.utc(date).tz('Asia/Tokyo').format(type);
}

export default getJstDateTime;
