// Functions
import getJstDateTime from '../functions/getjstdatetime';

// Components
import Loading from './loading';

const News = props => {
    
    if(props.contents.length >= 1){
        return(
            <ul className="news_list">
                {
                    props.contents.map((item, index) => {
                        return(
                            <li key={ index }>
                                <time dateTime={ getJstDateTime(item.date, 'YYYY-MM-DD') }>{ getJstDateTime(item.date, 'YYYY.MM.DD') }</time>
                                <a href={`/news/${ item.id }`}>
                                    <h3 className="news_title_ja">{ item.title_ja }</h3>
                                    <h3 className="news_title_en">{ item.title_en }</h3>
                                </a>
                            </li> 
                        )
                    })
                }
            </ul>
        )
    }else{
        return <p className="news_notfound">現在ニュースはありません。</p>
    }
}

export default News;
