import { createRoot } from 'react-dom/client';
import { RecoilRoot } from 'recoil';

// Components
import IndexNews from './components/indexnews';

const NewsArea = () => {
    
    return (
        <RecoilRoot>
            <IndexNews />
        </RecoilRoot>
    )
}

//レンダリング
const container = document.getElementById('newsArea');
const root = createRoot(container);
root.render(<NewsArea />);
