import { useEffect } from 'react';
import { createClient } from 'microcms-js-sdk';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ServiceDomainState, ApiKeyState, NewsDataState, isLoadingState } from '../recoil/atoms/newsatom';

// Components
import NewsList from './newslist';
import Loading from './loading';

const IndexNews = () => {
    // microCMSのドメイン
    const serviceDomain = useRecoilValue(ServiceDomainState);
    // microCMSのApiキー
    const apiKey = useRecoilValue(ApiKeyState);
    // 取得するデータ
    const [newsData, setNewsData] = useRecoilState(NewsDataState);
    // ローディングフラグ
    const [isLoading, setIsLoading] = useRecoilState(isLoadingState);
    //microcms-js-sdkの設定
    const client = createClient({
        serviceDomain: serviceDomain,
        apiKey: apiKey,
    });
    
    useEffect(() => {
        
        setIsLoading(true);
        
        // microCMSからデータを取得
        client.get({
            endpoint: 'news',
            queries: { limit: 3 },
        })
        .then((res) => {
            setNewsData(res.contents);
            setIsLoading(false);
        })
        .catch((err) => console.error(err));
        
    }, [])
    
    if(isLoading){
        return (
            <Loading />
        )
    }else{
        return (
            <NewsList contents={ newsData } />
        )
    }
}

export default IndexNews;
